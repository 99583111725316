/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

/* toastify */
/* todo refactor this, move to the component */
.Toastify__toast {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.6);
  background: #E7F3FE;
} 